import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MailIcon from "../../components/mail-icon"
import PageTitle from "../../components/page-title"

class BemVindo extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Bem-vindo" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PageTitle>Bem-vindo</PageTitle>
          <p style={{ marginTop: "50px" }}>
            Foi adicionado à nossa mailing list.
          </p>
          <p>Pode obter mais informação no email que lhe foi enviado.</p>
          <div style={{ width: "200px" }}>
            <MailIcon />
          </div>
        </div>
      </Layout>
    )
  }
}

export default BemVindo

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
